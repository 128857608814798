import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { MailIcon } from '@heroicons/react/solid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Layout from '../components/layout/layout';
import { isLoggedIn, logout } from '../utils/auth';
import { postApiRequest } from '../utils/request';
import Alert from '../components/alerts/alert';
import SEO from '../components/layout/seo';
import Panel from '../components/common/panel';

const ForgotPassword = () => {
  const [sent, setSent] = useState(false);
  useEffect(() => {
    if (isLoggedIn()) {
      logout();
    }
  }, []);


  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address.').required('Email is a required field.'),
    }),
    onSubmit: async (values, actions) => {
      // console.log(values, 'these are the values');
      // console.log(actions, 'actions');
      try {
        const response = await postApiRequest('/auth/forgot-password', values);
        console.log(response, 'response');
        setSent(true);
      } catch (err) {
        actions.setErrors({
          email: 'There was an error processing your request, please try again.',
        });
        // console.log(err.response, 'the error from the request');
      }
      actions.setSubmitting(false);
    },
  });

  const regularInputClasses = 'border-gray-300 placeholder-gray-500 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500';
  const errorInputClasses = 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500';

  const hasEmailError = formik.touched.email && formik.errors.email;

  return (

    <Layout classes="bg-gray-50">
      <SEO title="Forgot my password" />
      <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <Panel>
            <div className="py-6 px-4">
              <div>
                <Link to="/">
                  <img
                    className="mx-auto h-24 w-auto"
                    src="/fbl-logo.png"
                    alt="Fun Bowling Leagues Logo"
                  />
                </Link>
                <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">Request a password reset link</h2>
                {sent && (
                  <Alert type="info" title="Reset link sent" body="Check your email for the password reset link to continue." />
                )}
                {/* <p className="mt-2 text-center text-sm text-gray-600">
                  Or{' '}
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    start your 14-day free trial
                  </a>
                </p> */}
              </div>
              <form className="mt-8 space-y-6" onSubmit={formik.handleSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      {...formik.getFieldProps('email')}
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className={`${hasEmailError ? errorInputClasses : regularInputClasses} appearance-none relative block w-full px-3 py-2 border rounded-md focus:outline-none focus:z-10 sm:text-sm`}
                      placeholder="Email address"
                    />
                    {hasEmailError && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {formik.errors.email}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-between">

                  <div className="text-sm">
                    <Link to="/sign-in" className="font-medium text-indigo-600 hover:text-indigo-500">
                      Return to sign in.
                    </Link>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    disabled={formik.isSubmitting}
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <MailIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                    </span>
                    Send Password Reset
                  </button>
                </div>
              </form>
            </div>
          </Panel>
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
